import React, {useState} from 'react';
import HeroProj from "../Projects/HeroProj";


//use for job related stuff
function Resume(props) {

    return (

        <div>

            <HeroProj title='Resume Related'/>
            <p>put copy paste stuff here for job apps and other information sharing sites</p>

        </div>
    );
}

export default Resume;